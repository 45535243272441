// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "gradfee-20210802093138-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d2zhjoro65bquu.cloudfront.net"
};


export default awsmobile;
